<template>
  <!-- 预约挂号分类 -->
  <div class="classification">
    <div>
      <div
        class="classification-card"
        v-for="item in $route.query.id == 1 ? $store.state.typeList.slice(0, 2) : $store.state.typeList.slice(2, 4)"
        :key="item.id"
        @click="clickJump(item.url,item.name,item.stat,item.flag)"
      >
        <ul>
          <li class="classification-left">
            <svg-com
              size="3rem"
              :href="'#' + item.logo"
            />
          </li>
          <li class="classification-center">
            <p class="in-size">{{item.name}}</p>
            <span class="small-six-size">您还没有绑定院内卡,先来绑卡操作吧!</span>
          </li>
          <li>
            <svg-com href="#icon-youjiantou1-copy" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'classification',
  activated () {
    document.title = this.$route.query.title
  },
  methods: {
    //跳转
    clickJump (path, menuName, stat, flag) {
      if (stat == 0) {
        this.$store.dispatch('JUMP', { menuName, _this: this, path, flag })
      } else {
        this.$toast('维护中')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.classification {
  .classification-card {
    margin: 0.7rem auto;
    box-sizing: border-box;
    width: 95vw;
    padding: 1.5rem 1rem;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .classification-left {
        flex: 0.2;
        svg-com {
          font-size: 3rem;
        }
      }
      .classification-center {
        display: flex;
        flex-direction: column;
        flex: 0.8;
        white-space: nowrap;
        span {
          line-height: 1.5rem;
        }
        span:last-child {
          color: rgb(153, 150, 150);
        }
      }
    }
  }
}
</style>